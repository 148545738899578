#abouttext {
	font-size: 3.8vw;
	text-align: justify;
	padding: 50px;
	font-family: 'Overpass', sans-serif;
	text-transform: uppercase;
	font-weight: 400;
	-webkit-text-stroke-width: 0px;
	-webkit-text-stroke-color: black;
	color: black;
	background-color: white;
	line-height: 2;
	z-index: 2;
	margin-top: -10px;
}

#abouttext>span {
	cursor: pointer;
}

#aboutback {
	font-size: 20px;
	color: black;
	font-family: 'Overpass', sans-serif;
	font-weight: 200;
	cursor: pointer;
}


@media screen and (max-width: 566px) {
    #abouttext {
        font-size: 3vh;
        padding: 10px;
    }
}