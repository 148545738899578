/* ISSUES PAGE */
#issuetext {
	font-family: 'Raleway', sans-serif;
	font-size: min(8.4vw, 100px);
	font-weight: 200;
	-webkit-text-stroke-width: 0px;
	-webkit-text-stroke-color: black;
	color: black;
	text-align: left;
	margin-top: 40vh;
	margin-left: 10px;
	position: -webkit-sticky;
	position: sticky;
	top: 40vh;
	align-self: flex-start;
}

.issuetheme {
	font-family: 'Raleway', sans-serif;
	font-size: min(8.4vw, 100px);
	font-weight: 200;
	-webkit-text-stroke-width: 0px;
	-webkit-text-stroke-color: black;
	color: black;
	text-align: right;
	position: -webkit-sticky;
	position: sticky;
	align-self: flex-start;
	margin-left: auto;
	/* min-width: 24.7vw;
	max-width: 24.7vw; */
	width: 23.5vw;
	height: 10vw;
	padding: 0;
}

#issuethemedown {
	margin-top: 40vh;
	top: 40vh;
	width: 23vw;
	height: 10vw;
}

#issues {
	/* background-color: white; */
	display: flex;
	height: 100%;
	width: 100%;
}

#issuenumber {
	font-family: 'Raleway', sans-serif;
	font-size: 600px;
	font-weight: 800;
	text-align: center;
	margin-top: 43vh;
	padding-bottom: 0vh;
	margin-bottom: 0vh;
	margin-left: 12.5vw;
	display: inline-block;
	cursor: pointer;
	line-height: 13vh;
}

.issuenumber>span {
	text-decoration: underline;
	text-decoration-color: #0000EE;
}

.space {
	height: 80vh;
}

#coverimg {
	width: 100vw;
	height: auto;
	display: block;
	margin-right: auto;
	margin-left: auto;
	/* z-index: 999; */
	filter: blur(5px);
	pointer-events: none;
	position: fixed;
	z-index: -1;
	top: 0%;
	left: 0%;
}

#cover {
	pointer-events: none;
	position: fixed;
	z-index: -1;
	top: 0%;
	left: 0%;
	transition-duration: 500ms;
}

#issuesback {
	position: fixed;
	font-size: 20px;
	color: black;
	font-family: 'Raleway', sans-serif;
	font-weight: 200;
	margin-left: 10px;
	margin-top: 10px;
	cursor: pointer;
}

@media screen and (max-width: 566px) {
	#issuenumber {
		font-size: 12.5vh;
		margin-left: 0;
	}

	#issuetext {
		font-size: min(8.4vw, 100px);
	}

	.issuetheme{
		margin-left: 5vw;
	}

	#coverimg{
		height: 120vh;
		width: auto; 
	}
	#issuesback{
		color: white;
	}
}