html {
    max-width:100vw;
}
body {
    margin: 0;
}

.viewer {
    font-family: Segoe UI, Tahoma, sans-serif;
    background-color: #525659;
}

.viewer header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    
    color: white;
    display: flex;
}

.viewer header h1 {
    text-align: right;
    margin:auto;
}

.viewer header .back {
    color: white;
    font-size: 2vh;
    padding: 20px;
    margin:auto;
    font-family: Segoe UI, Tahoma, sans-serif;
}

.viewer header h1 {
    font-size: 2vh;
    margin-left: 90%;
}

.doc-continaer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
}

.doc-container__load {
    margin-top: 1em;
    color: white;
}

.doc {
    margin: 1em 0;
}

.doc .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.doc .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
}

.doc .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
}

.doc .react-pdf__message {
    padding: 20px;
    color: white;
}

@media screen and (max-width: 566px) {
    .viewer{
        width:100%;
    }
    .viewer header h1 {
        font-size: 2vh;
        margin-left: 60%;
        padding-top:10px;
    }
}