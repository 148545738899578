.countdown{
    min-height: 60vh;
    justify-content: center;
    z-index: -1;
    padding:3%;
    max-height: 100vh;
    overflow:hidden;
    margin-top:-10%;
}
/* .clock-container{
    
    align-content: center;
    text-align: center;
    justify-items: center;
} */

.clock{
    text-align: center;
    justify-content: center;
    position:absolute;
    left:0;
    width:100%;

    z-index: 0;
    color:black;
    font-size: 45vw;
    
    /*font-family: 'Raleway', sans-serif;*/
	font-weight: 900;
	line-height: 1.05;
    
	-webkit-text-stroke-width: 0.1%;
	-webkit-text-stroke-color: white;
    margin-left:auto;
    margin-right:auto;
}
.clock img {
    /* overflow:hidden; */
    height: 105vh;
    transform-origin:center;
    animation: clock-rotation calc(var(--animation-speed)/5) ease-in-out infinite;
    animation-direction: normal;
}
#dim-1{
    animation-delay: 50ms;
} #dim-2{
    animation-delay: 100ms;
}
#dim-3{
    animation-delay: 150ms;
}
#dim-4{
    animation-delay: 200ms;
}
#dim-5{
    animation-delay: 250ms;
}

@keyframes clock-rotation{
    0%{
        transform:Rotate(0deg);
    }
    100%{
        transform:Rotate(359.9deg);
    }
    
}


.issue-title{
    position:relative;
    z-index: 2;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
    padding-bottom: 0;
    text-align: center;
    min-height:38vh;
    font-family: 'Raleway', sans-serif;
	font-weight: 900;
	line-height: 1.05;
	color: white;
    font-size: 17.5vh;
}

.t-minus{
    position:relative;
    z-index: 2;
    text-align: center;
    font-family: 'Raleway', sans-serif;
	font-weight: 900;
	line-height: 1.05;
    font-size: 10vh;
	color: black;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: white;
}


@media screen and (max-width: 566px) {
    .clock img{
        width: auto;
        height: 82.5vh;
    }
    .countdown{
        min-height: 30vh;
    }
    .issue-title{
        font-size: 15vw;
        min-height: 20vh;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
    }
    .clock{
        animation-duration: calc(var(--animation-speed)/5);
        font-size:50vw;
    }
    .t-minus{
        font-size: 15vw;
    }
}