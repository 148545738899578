.pedestal{
    min-height: 60vh;
    justify-content: center;
    z-index: -1;
    padding-top:3%;
    padding-bottom:0;
    
}
.container-container{
    display:flex;
    column-gap:0;
}
.cov-dimension-container{
    display:flex;
    justify-content: center;
    align-content: center;
    position:relative;
    margin-top:-3%;
    margin-bottom:0%;
    min-height:60vh;
    width:100%;
    vertical-align: center;
    text-align: center;
}

.cov-dimension{
    display:flex;
    justify-content: center;
    align-content: center;
    justify-self: center;
    text-align: center;
    position:absolute;
    height:60vh;

    z-index: 0;
    color:black;
    font-size: 45vw;
    
    /*font-family: 'Raleway', sans-serif;*/
	font-weight: 900;
	line-height: 1.05;
    
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: white;
    

}
.cov-center{
    .cov-dimension{
    animation: cov-expansion calc(var(--animation-speed)/10) ease infinite;
    animation-direction: alternate;
    }
    
}
.cov-side-left{
    .cov-dimension{
    animation: cov-expansion-left calc(var(--animation-speed)/10) ease infinite;
    animation-direction:alternate;
    }
}
.cov-side-right{
    .cov-dimension{
    animation: cov-expansion-right calc(var(--animation-speed)/10) ease infinite;
    animation-direction: alternate;
    }
}

.cov-dimension.cdim-5{
    animation-delay: 500ms;
}
.cov-dimension.cdim-4{
    animation-delay: 1000ms;
}
.cov-dimension.cdim-3{
    animation-delay: 1500ms;
}
.cov-dimension.cdim-2{
    animation-delay: 2000ms;
}
.cov-dimension.cdim-1{
    /* animation:none; */
    animation-delay:2500ms;
}

.cov-dimension>img{
    height:100%;
    max-width: 60vw;
    border-radius: 2%;
}



@keyframes cov-expansion-left{
    0%{
        transform:scale(100%);
    }
    100%{

        transform:translate(30%,0) scale(33%);
    }
}

@keyframes cov-expansion-right{
    0%{
        transform:scale(100%);
    }
    100%{
        transform:translate(-30%,0) scale(33%);
    }
}
@keyframes cov-expansion{
    0%{
        transform:scale(100%);
    }
    100%{
        transform:scale(33%);
    }
}

#cov-banner{
    position:relative;
    /* height: fit-content; */
    text-align: center;
    font-family: 'Raleway', sans-serif;
	font-weight: 900;
	line-height: 1.05;
	color: black;
	background-color: white;
    font-size: 10vh;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: white;
}

@media screen and (max-width: 566px) {
    .cov-side-right{
        display:none;
    }
    .cov-side-left{
        display:none;
    }
    .cov-dimension{
        width:100vw;
    }
    .cov-dimension>img{
        max-width: 100vw;
        border-radius: 2%;
    }
    #cov-banner{
        font-size: 14.5vw;
    }
}